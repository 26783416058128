import React, { useContext, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles(theme => ({
	root: {
		width: "100vw",
		height: "100vh",
		//background: "linear-gradient(to right, #76EE00 , #76EE00 , #458B00)",
		backgroundImage: "url(https://i.ibb.co/600MZhC/suporte-tecnico-ti-hero.webp)",
		backgroundRepeat: "no-repeat",
		backgroundSize: "100% 100%",
		backgroundPosition: "center",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		textAlign: "center",
	},
}));

const useQuery = () => {
	return new URLSearchParams(useLocation().search);
};

const LoginWorkspace = () => {
	const { handleLoginWorkspace } = useContext(AuthContext);
	const query = useQuery();

	useEffect(() => {
		const jsonEncode = query.get('jsonEncode');
		if (jsonEncode) {
			handleLoginWorkspace(jsonEncode);
		}
	}, [query, handleLoginWorkspace]);

	return (
		<h1>teste</h1>
	);
};

export default LoginWorkspace;
