import React, { useContext } from "react";
import { Redirect, Route as RouterRoute } from "react-router-dom";

import BackdropLoading from "../components/BackdropLoading";
import { AuthContext } from "../context/Auth/AuthContext";

const Route = ({ component: Component, isPrivate = false, ...rest }) => {
	const { isAuth, loading } = useContext(AuthContext);

	if (loading) {
		return <BackdropLoading />
	}

	if (!isAuth && isPrivate) {
		window.location.href = process.env.REACT_APP_WORKSPACE_URL
		return
	}

	if (isAuth && !isPrivate) {
		return (
			<>
				<Redirect to={{ pathname: "/", state: { from: rest.location } }} />;
			</>
		);
	}

	return (
		<>
			<RouterRoute {...rest} component={Component} />
		</>
	);
};

export default Route;
